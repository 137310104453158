<template>
  <div class="app-wrap">
    <el-container class="app-container">
      <el-main>
        <el-table :data="tableData">
          <el-table-column type="expand">
            <template #default="{ row }">
              <el-descriptions class="expand-padding" :column="2">
                <el-descriptions-item label="邮箱：">
                  <el-link
                    v-if="row.email"
                    type="primary"
                    :icon="Message"
                    :underline="false"
                    :href="`mailto:${row.email}`"
                  >{{ row.email }}</el-link>
                </el-descriptions-item>
                <el-descriptions-item label="电话：">
                  <el-link
                    v-if="row.company_mobile"
                    type="primary"
                    :icon="Phone"
                    :underline="false"
                    :href="`tel:${row.company_mobile}`"
                  >{{ row.company_mobile }}</el-link>
                </el-descriptions-item>
                <el-descriptions-item label="地址：">
                  {{ row.company_address }}
                </el-descriptions-item>
                <el-descriptions-item label="开户行账号：">
                  {{ row.company_card }}
                </el-descriptions-item>
              </el-descriptions>
            </template>
          </el-table-column>
          <el-table-column label="发票编号" prop="fpbh" align="center" width="180"></el-table-column>
          <el-table-column label="企业" prop="company_name" align="center"></el-table-column>
          <el-table-column label="税号" prop="nsrsbh" align="center" width="180"></el-table-column>
          <el-table-column label="开票金额" prop="xmje" align="right" width="100"></el-table-column>
          <el-table-column label="价税合计" prop="jshj" align="right" width="100"></el-table-column>
          <el-table-column label="状态" align="center" width="100">
            <template #default="{ row }">
              <el-tag v-if="row.status === 0">未审核</el-tag>
              <el-tag v-if="row.status === 1" type="success">通过</el-tag>
              <el-tooltip
                v-if="row.status === 2"
                placement="top"
                :content="row.refuse"
              >
                <el-tag type="info">失败</el-tag>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="100">
            <template #default="{ row }">
              <el-button
                v-if="row.status === 0"
                type="primary"
                @click="examine(row.id)"
              >审核</el-button>
              <el-button
                v-if="row.status === 1"
                type="success"
                @click="invoicing(row.id)"
              >开票</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer height="auto">
        <tj-pagination
          v-model="page"
          @change="getList"
        ></tj-pagination>
      </el-footer>
    </el-container>
  </div>
  <Examine ref="examineRef" @submit="getList"></Examine>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Message, Phone } from '@element-plus/icons-vue';
import { usePagination } from '@tj/ui';
import request from '@/hooks/request';
import Examine from './Examine.vue';

const { page } = usePagination();
const tableData = ref([]);

function getList() {
  request.get('/admin/order/noteList', {
    params: {
      page: page.page,
      per_page: page.limit,
    },
  }).then((data) => {
    tableData.value = data.list;
    page.total = data.total;
  });
}

const examineRef = ref();
function examine(id) {
  examineRef.value.open(id);
}

function invoicing(id) {
  request.post(`/admin/order/open/${id}`).then((data) => {
    console.log(data);
  });
}

function getUrl(fpbh) {
  request.post(`/admin/order/url?fpbh=${fpbh}`).then((data) => {
    console.log(data);
  });
}

onMounted(() => {
  getList();
});
</script>
